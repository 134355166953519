import React from "react"

const Rail = ({ window }) => {
  return (
    <div className="card mb--m" style={{ border: "1px solid #555" }}>
      <span style={{ fontWeight: "bold" }}>Rail</span> - <br />
      <span style={{ fontWeight: "bold" }}>Bredde</span> -{" "}
      {window.fields["Finished curtain width"]}
    </div>
  )
}

export default Rail
