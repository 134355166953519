import React from "react"

const index = ({ window }) => {
  return (
    <div className="card mb--m" style={{ border: "1px solid #555" }}>
      <span style={{ fontWeight: "bold" }}>Gardin</span> -{" "}
      {window.fields["Curtain fabric"]} {window.fields["Color"]}
      <br />
      {window.fields["Inner curtain fabric"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Inder gardin</span> -{" "}
          {window.fields["Inner curtain fabric"]}{" "}
          {window.fields["Inner curtain color"]}
          <br />
        </>
      )}
      {window.fields["Pieces"] && (
        <>
          <span style={{ fontWeight: "bold" }}>Antal gardinstykker</span> -{" "}
          {window.fields["Pieces"]}
          <br />
        </>
      )}
      <span style={{ fontWeight: "bold" }}>Bredde</span> -{" "}
      {window.fields["Finished curtain width"]}
      <br />
      <span style={{ fontWeight: "bold" }}>Højde</span> -{" "}
      {window.fields["Finished curtain height"]}
      <br />
    </div>
  )
}

export default index
