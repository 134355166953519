import React, { useContext, useState, useEffect } from "react"
import useProject from "../utils/useProject"
import Curtain from "../components/PurchaseOrder/Curtain"
import Rail from "../components/PurchaseOrder/Rail"

const PurchaseOrder = () => {
  let projectId
  let supplierId

  if (typeof document !== "undefined") {
    let params = new URLSearchParams(document.location.search.substring(1))
    projectId = params.get("id") // is the string "Jonathan"
    supplierId = params.get("sid") // is the string "Jonathan"
  }

  // const project = useProject(projectId)

  const project = useProject(projectId)

  if (!project) {
    return "Loading"
  }

  console.log("supplier", supplierId)

  return (
    <div
      className="measuring-guide-container co--light lh--m mb--l article project"
      style={{ paddingBottom: "80px" }}
    >
      <h1>Bestilling #{projectId}</h1>

      <div style={{ marginBottom: 40 }}>Forventet leveringsdato:</div>

      {project && (
        <>
          <h2 className="fs--m" style={{ fontSize: 25, fontWeight: "300" }}>
            Leveres til
          </h2>
          <div className="card mb--m" style={{ border: "1px solid #555" }}>
            <span style={{ fontWeight: "bold" }}>Email</span> -{" "}
            {project.project.fields["Email"]}
            <br />
            <span style={{ fontWeight: "bold" }}>Telefon</span> -{" "}
            {project.project.fields["Phone number"]}
            <br />
            <span style={{ fontWeight: "bold" }}>Adresse</span> -{" "}
            {project.project.fields["Street name"]},{" "}
            {project.project.fields["Zipcode"]} {project.project.fields["City"]}
          </div>
        </>
      )}

      <hr style={{ margin: "40px 0 40px" }} />

      {project &&
        project.windows.records.map((window, index) => {
          if (supplierId === "839483") {
            return <Curtain window={window} key={index} />
          } else if (supplierId === "839484") {
            return <Rail window={window} key={index} />
          } else {
            return null
          }
        })}
    </div>
  )
}

export default PurchaseOrder
